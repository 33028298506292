@import '../../../styles/basics';

.site-overlay-header {
  padding: 16px 8px;
  display: flex;
  align-items: flex-start;
  position: relative;

  @include media-breakpoint-up(lg) {
    padding: 26px 32px;
  }

  // Mobile menu expanding animations.
  // Scrolling animations sits in components/02_molecules/StickyNav/index.module.scss.
  &:global(.site-overlay-header--menu-expanded) {
    :global {
      @include media-breakpoint-down(lg) {
        .page-type__navbar {
          margin-left: 0;
        }

        .page-type__site-logo {
          pointer-events: none;
        }
      }

      .page-type__navbar-bottom {
        max-height: 500px;
        transition: .3s ease;

        @include media-breakpoint-up(lg) {
          max-height: 0;
        }
      }

      .page-type__dropdown {
        display: block;

        @include media-breakpoint-up(lg) {
          display: none;
        }
      }
    }
  }

  &:global(.site-overlay-header--full-width) {
    :global {
      .page-type__navbar {
        margin-left: 0;
      }

      .page-type__site-logo {
        pointer-events: none;
      }
    }
  }

  :global {
    .page-type__site-logo {
      overflow: hidden;
      padding-left: 16px;
      padding-right: 20px;
      height: 70px;
      display: flex;
      align-items: center;
      transition: .2s ease;
      flex: 0 0 auto;
      position: absolute;

      @include media-breakpoint-up(md) {
        padding-left: 20px;
        padding-right: 30px;
      }

      @include media-breakpoint-up(lg) {
        height: 80px;
        padding-left: 4px;
      }

      a {
        // Disabling green hover color.
        &:hover path {
          fill: $white;
        }
      }

      .logo-white {
        width: 88px;

        @include media-breakpoint-up(md) {
          width: 109px;
        }

        @include media-breakpoint-up(lg) {
          width: 148px;
        }
      }
    }

    .page-type__navbar {
      position: relative;
      padding: 16px 24px;
      background: $white;
      border-radius: 36px;
      border: 1px solid $slate-200;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      transition: .2s ease;
      margin-left: 124px;

      @include media-breakpoint-up(md) {
        margin-left: 159px;
      }

      @include media-breakpoint-up(lg) {
        margin-left: 182px;
        border-radius: 40px;
        padding: 17px 32px;
      }
    }

    .page-type__navbar-top {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media (min-width: 360px) {
        padding-left: 8px;
        padding-right: 8px;
      }
    }

    .page-type__navbar-top-left,
    .page-type__navbar-top-right {
      display: flex;
      align-items: center;
    }

    .page-type__navbar-bottom {
      // Just "height" property won't be animated from 0 to auto.
      // See https://stackoverflow.com/a/8331169/2220424 .
      // However it can be improved by getting exact menu height.
      max-height: 0;
      overflow: hidden;
      transition: .15s ease;

      @media (min-width: 360px) {
        padding-left: 2px;
        padding-right: 2px;
      }
    }

    .page-type__dropdown {
      display: none;
      padding: 24px 0 8px;
      flex-direction: column;
    }

    // All the following styles are about to fit any image
    // to the specific box without fixed width (only max-width)
    // but keep the aspect ratio of the image.
    .page-type__logo {
      height: 38px;
      max-width: 90px;
      position: relative;
      display: flex;
      align-items: center;

      a, img {
        display: block;
      }

      img {
        width: 100%;
        max-width: 100%;
        height: auto;
        max-height: 38px;
      }

      @include media-breakpoint-up(md) {
        max-width: 109px;
      }

      @include media-breakpoint-up(lg) {
        height: 44px;
        margin-right: 28px;
        max-width: 148px;

        img {
          max-height: 44px;
        }
      }
    }

    .page-type__menu-toggle {
      .navigation {
        display: flex;
        flex-direction: column;
        position: relative;
        width: 37px;
        height: 38px;
        padding: 0 6px 13px;
        align-items: center;

        &:after {
          content: 'Menu';
          font-weight: $font-weight-bold;
          font-size: 12px;
          line-height: 1;
          position: absolute;
          color: $gray-1200;
          bottom: 0;
          text-transform: uppercase;
        }

        &.toggled {
          &:after {
            content: 'Close';
          }
        }
        &:hover {
          cursor: pointer;
        }
      }

      @include media-breakpoint-up(lg) {
        display: none;
      }
    }

    .page-type__menu-mobile {
      .menu-item {
        border-top: 1px solid rgba(#000, .1);

        &:last-child {
          border-bottom: 1px solid rgba(#000, .1);
        }
      }

      a {
        display: block;
        font-size: 18px;
        font-weight: $font-weight-bold;
        color: $gray-1200;
        line-height: $line-height-large;
        padding: 24px 22px;
      }
    }

    .page-type__cta-mobile {
      margin: 20px 0 0;
      width: 100%;
    }

    .page-type__menu-desktop {
      display: none;

      @include media-breakpoint-up(lg) {
        display: flex;

        .menu-item {
          padding: 0 2px;
        }

        a {
          display: block;
          padding: 9px 16px 9px;
          font-size: 18px;
          line-height: 28px;
          font-weight: $font-weight-bold;
          color: $gray-1200;
          border-radius: 23px;

          // Default colors are the same as for
          // .site-overlay-header--variant-one-planet.
          &:hover,
          &.is-active {
            text-decoration: none;
            color: $slate-1200;
            background: $blue-100;
          }

          &.is-active {
            padding: 8px 15px 8px;
            border: 1px solid $blue-800;
          }
        }
      }
    }

    .page-type__cta-desktop {
      display: none;

      @include media-breakpoint-up(lg) {
        display: block;
        margin: -4px -21px -4px 0;
      }
    }
  }

  &.site-overlay-header--variant-project-us {
    :global {
      .page-type__menu-desktop {
        @include media-breakpoint-up(lg) {
          a {
            color: $gray-1200;

            &:hover,
            &.is-active {
              color: $gray-1200;
              background: $red-200;
            }

            &.is-active {
              border: 1px solid $red-800;
            }
          }
        }
      }
    }
  }
}
