@import '../../../styles/basics';

.empty-space-behind-nav {
  width: 100%;
  height: 65px;

  @include media-breakpoint-up(lg) {
    height: 108px;
  }
}

.sticky-nav {
  position: fixed;
  top: 0;
  left: 0;
  transition: all 0.2s ease-in-out;
  width: 100%;
  z-index: 6500;

  :global {
    .site-header {
      transition: all 0.2s ease-in-out;
    }

    .header-toplinks {
      transition: all 0.2s ease-in-out;
    }

    .logo {
      transition: all 0.2s ease-in-out;
    }

    // Scrolling to menu if viewport less than menu.
    .header-dropdown.desktop {
      > .links {
        margin-top: 0;
        padding-top: 48px;
        overflow-y: auto;
        max-height: calc(100vh - 110px);
      }

      > .cta {
        overflow-y: auto;
        max-height: calc(100vh - 110px);
        padding-right: 8px;
      }
    }

    .header-dropdown.mobile {
      overflow-y: auto;
      max-height: calc(100vh - 65px);
    }
  }

  &:not(:global(.top)) {
    :global {
      .site-overlay-header {
        .page-type__navbar {
          margin-left: 0;
        }

        .page-type__site-logo {
          pointer-events: none;
        }
      }

      @include media-breakpoint-up(lg) {
        .site-header {
          padding-top: 5px;
          padding-bottom: 5px;

          .header-menu {
            .left {
              margin-bottom: 0px;
            }

            .search {
              &.toggled {
                padding-bottom: 17px;
              }
            }
          }
        }

        .header-toplinks {
          position: absolute;
          top: -50px;
          height: 0 !important;
          margin-bottom: 0;
          opacity: 0;
        }

        .logo {
          margin-top: 5px !important;
          height: 35px !important;
        }

        // Scrolling to menu if viewport less than menu.
        .header-dropdown.desktop {
          > .links {
            max-height: calc(100vh - 55px);
          }

          > .cta {
            max-height: calc(100vh - 55px);
          }
        }
      }
    }
  }
}
